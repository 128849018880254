import { ReactElement, ReactNode } from "react";
export const LOGS_LOADING = "LOGS_LOADING";
export const LOGS_FAILURE = "LOGS_FAILURE";
export const LOGS_SUCCESS = "LOGS_SUCCESS";

export type Logs = {
  id: number;
  DCM: string;
  Logs: Array<LogsObject>;
};

export type LogsObject = {
  UpdateBy: string;
  LastUpdate: string;
  Timezone: string;
  Power: LoadObject;
};

export type LoadObject = {
  On: boolean;
  Disable: boolean;
  Monday: StartEndObject;
  Tuesday: StartEndObject;
  Wednesday: StartEndObject;
  Thursday: StartEndObject;
  Friday: StartEndObject;
  Saturday: StartEndObject;
  Sunday: StartEndObject;
};

export type StartEndObject = {
  on: string;
  off: string;
};

export interface LogsLoading {
  type: typeof LOGS_LOADING;
}

export interface LogsFailure {
  type: typeof LOGS_FAILURE;
}

export interface LogsSuccess {
  type: typeof LOGS_SUCCESS;
  payload: Array<Logs>;
}

export type LogsDispatchTypes = LogsLoading | LogsFailure | LogsSuccess;
