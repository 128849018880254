import { ReactElement, ReactNode } from "react";
export const RESETRELAY_LOADING = "RESETRELAY_LOADING";
export const RESETRELAY_FAILURE = "RESETRELAY_FAILURE";
export const RESETRELAY_SUCCESS = "RESETRELAY_SUCCESS";

export type ResetRelay = {
  id: number;
  DCM: string;
  Timezone: string;
  UpdateBy: string;
  LastUpdate: string;
  Power: LoadObject;
};

export type LoadObject = {
  On: boolean;
  Disable: boolean;
  Monday: StartEndObject;
  Tuesday: StartEndObject;
  Wednesday: StartEndObject;
  Thursday: StartEndObject;
  Friday: StartEndObject;
  Saturday: StartEndObject;
  Sunday: StartEndObject;
};

export type StartEndObject = {
  on: string;
  off: string;
};

export interface ResetRelayLoading {
  type: typeof RESETRELAY_LOADING;
}

export interface ResetRelayFailure {
  type: typeof RESETRELAY_FAILURE;
}

export interface ResetRelaySuccess {
  type: typeof RESETRELAY_SUCCESS;
  payload: Array<ResetRelay>;
}

export type ResetRelayDispatchTypes =
  | ResetRelayLoading
  | ResetRelayFailure
  | ResetRelaySuccess;
