import {
  ResetRelayDispatchTypes,
  RESETRELAY_LOADING,
  RESETRELAY_FAILURE,
  RESETRELAY_SUCCESS,
  ResetRelay,
} from "../actions/resetRelayActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  resetRelay?: Array<ResetRelay>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const resetRelayReducer = (
  state: DefaultStateInterface = defaultState,
  action: ResetRelayDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case RESETRELAY_FAILURE:
      return {
        loading: false,
      };
    case RESETRELAY_LOADING:
      return {
        loading: true,
      };
    case RESETRELAY_SUCCESS:
      return {
        loading: false,
        resetRelay: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type ResetRelayRootState = ReturnType<typeof resetRelayReducer>;

export default resetRelayReducer;
