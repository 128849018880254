import { ReactElement, ReactNode } from "react";
export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";

export type Notifications = {
  Notifications: Array<NotificationsObject>;
};

export type NotificationsObject = {
  id: number;
  User: string;
  Email: string;
  Alerts: Array<string>;
};

export interface NotificationsLoading {
  type: typeof NOTIFICATIONS_LOADING;
}

export interface NotificationsFailure {
  type: typeof NOTIFICATIONS_FAILURE;
}

export interface NotificationsSuccess {
  type: typeof NOTIFICATIONS_SUCCESS;
  payload: Array<NotificationsObject>;
}

export type NotificationsDispatchTypes =
  | NotificationsLoading
  | NotificationsFailure
  | NotificationsSuccess;
